import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class QueryParamsMapperService {
  /**
 * Converts a string to PascalCase.
 * @param str The input string to convert.
 * @returns The PascalCase version of the input string.
 */
  private toPascalCase(str: string): string {
    return str.replace(/(^\w|_\w)/g, match => match.replace('_', '').toUpperCase());
  }

  /**
 * Checks if an object has a property.
 * @param obj The object to check.
 * @param prop The property to check for.
 * @returns True if the property exists on the object, false otherwise.
 */
  private hasOwnProperty<T>(obj: T, prop: string): boolean {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  }

  /**
   * Maps the keys of an object to a new format where each key is converted to PascalCase and nested keys are appended to their parent keys.
   * 
   * @param obj The input object to map.
   * @param parentKey The parent key to append to the child keys. Defaults to an empty string.
   * @returns A new object with keys formatted in PascalCase and nested keys appended to their parent keys.
   * 
   * @example
   * // Input
   * const queryParams = {
   *   paging: {
   *     size: 10,
   *     index: 1
   *   }
   * };
   * 
   * // Output
   * // {
   * //   'Paging.Size': 10,
   * //   'Paging.Index': 1
   * // }
   * 
   * const mappedQueryParams = queryParamsMapper(queryParams);
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapQueryParams<T>(obj: T, parentKey = ''): Record<string, any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: Record<string, any> = {};

    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.hasOwnProperty(obj, key)) {
        const newKey = parentKey
          ? `${parentKey}.${this.toPascalCase(key)}`
          : this.toPascalCase(key);
        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          result = { ...result, ...this.mapQueryParams(obj[key] as Record<string, any>, newKey) };
        } else {
          result[newKey] = obj[key];
        }
      }
    }

    return result;
  }
}