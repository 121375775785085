export enum Icons {
    Ayandeh = "Ayandeh",
    EqtesadeNovin = "Eqtesad-e-Novin",
    EtebariTosseeh = "EtebariTosse-eh",
    HSBC = "HSBC",
    KarAfarin = "KarAfarin",
    Keshavarze = "Keshavarze",
    KhavarEMiyaneh = "Khavar-e-Miyaneh",
    Maskan = "Maskan",
    MehreIran = "Mehr-e-Iran",
    Noor = "Noor",
    PostBank = "PostBank",
    Qavamin = "Qavamin",
    Resalat = "Resalat",
    Sina = "Sina",
    Ansar = "Ansar",
    Dey = "Dey",
    Gardeshgari = "Gardeshgari",
    HekmatIranian = "HekmatIranian",
    IndustryandMine = "IndustryandMine",
    Iranzamin = "Iranzamin",
    Mellat = "Mellat",
    Melli = "Melli",
    Pasargad = "Pasargad",
    Persian = "Persian",
    Refah = "Refah",
    Saderat = "Saderat",
    Saman = "Saman",
    Sarmaye = "Sarmaye",
    Shahr = "Shahr",
    ToseeTaavon = "ToseeTaavon",
    Dirham = "Dirham",
    Sepah = "Sepah",
    Dollar = "Dollar",
    Euro = "Euro",
    FXTM = "FXTM",
    Lira = "Lira",
    Liteforex = "Liteforex",
    Rial = "Rial",
    Ruble = "Ruble",
    Yuan = "Yuan",
}