import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormatter',
  standalone: true
})
export class CurrencyFormatterPipe implements PipeTransform {

  transform(value: number | string, currency: 'IRR' | 'USDT' = 'IRR'): string {
    if (value == null || value === '') return '';

    const numericValue = typeof value === 'number' ? value : parseFloat(value);
    if (isNaN(numericValue)) return '';

    // Format the number with commas as thousands separators
    const formattedValue = numericValue.toLocaleString('en-US');

    // Append the currency symbol
    return `${formattedValue} ${currency}`;
  }

}
