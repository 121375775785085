/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, ElementRef, input, viewChild, ChangeDetectionStrategy, inject, ChangeDetectorRef, Input } from '@angular/core';
import { ControlValueAccessor, ReactiveFormsModule, FormsModule, NgControl } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { ROUTES } from '@core/constants';
import { MatInputModule } from '@angular/material/input';
import { FileSizePipe, JoinAndUppercasePipe, TruncatePipe } from '@shared/pipes';
import { DownloadFileDirective } from '@shared/directives';
import { getInputFirstErrorMessage, transformToBoolean } from '@core/utils';
import { FileManagementService } from '@core/services';
import { Attachment } from '@core/models';
import { FoldersEnum } from '@core/enums';

@Component({
  selector: 'app-file-uploader',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FileSizePipe,
    DownloadFileDirective,
    JoinAndUppercasePipe,
    TruncatePipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './file-uploader.component.html',
  styleUrl: './file-uploader.component.scss',
})
export class FileUploaderComponent implements ControlValueAccessor {
  fileInput = viewChild<ElementRef<HTMLInputElement>>('fileInput');
  label = input('File Upload');
  required = input(false, {
    transform: transformToBoolean
  })
  dragDropMessage = input('drag and drop file here or')
  chooseFileMessage = input('Browse files')
  acceptedFormats = input(['.jpg', '.png'])
  maxFileSize = input(1 * 1024 * 1024); //1 MB
  minFileSize = input(0)
  disabled = input(false)
  autoUpload = input(false, {
    transform: transformToBoolean
  })
  uploadTo = input(ROUTES.file.upload);
  folder = input<FoldersEnum>(FoldersEnum.Bank);
  downloadFrom = input(ROUTES.file.upload);
  @Input() attachment:Attachment | undefined;

  selectedFile: File | null = null;
  hasFile = false;
  hasError = false;
  filePreview: string | ArrayBuffer | null = null;
  errorMessage = '';
  fileId: number | null = null;
  cdr = inject(ChangeDetectorRef)
  ngControl = inject(NgControl);
  isDragOver = false;
  fileService = inject(FileManagementService)
  private _onChange: (value: number | null) => void = () => { };
  private _onTouched: () => void = () => { };
  constructor() {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(value: number | null): void {
    this.fileId = value;
    // TODO: If fileId is provided, you might want to fetch file details from the server
    if(this.attachment){
      this.filePreview = this.attachment.url;
      this.hasFile = true;
    }
    this.checkForErrorMessages();
  }

  registerOnChange(fn: (value: number | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDisabledState(isDisabled: boolean): void {
    // this.disabled.update(_=>isDisabled);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.disabled()) {
      this.isDragOver = true;
      event.dataTransfer!.dropEffect = 'copy';
    }
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.disabled()) {
      this.isDragOver = false;
      const files = event.dataTransfer!.files;
      if (files.length) {
        this.selectedFile = files[0];
        this.handleFile(this.selectedFile);
      }
    }
  }
  chooseFile() {
    this.fileInput()?.nativeElement.click();
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this._onChange(null)
      this.selectedFile = input.files[0];
      this.handleFile(this.selectedFile);
    }
  }

  handleFile(file: File) {
    this.hasError = false;

    if (!this.isValidFileType(file)) {
      this.setError('Invalid file type');
      return;
    }

    if (file.size > this.maxFileSize() || file.size < this.minFileSize()) {
      this.setError('File size not within allowed range');
      return;
    }

    this.hasFile = true;

    if (this.isImage) {
      const reader = new FileReader();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      reader.onload = (_) => {
        const blob = new Blob([file], { type: file.type });
        this.filePreview = URL.createObjectURL(blob);
        this.cdr.detectChanges();
      };
      reader.readAsArrayBuffer(file);
    }

    if (this.autoUpload()) {
      this.uploadFile(file);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  uploadFile(file: File) {
    // TODO: Implement file upload logic here
    this.fileService.uploadFile(file, this.folder()).subscribe(result=>{
      if(result.fileId){
        this._onChange(result.fileId)
      }
    })
  }
  initializeUploader() {
    this.hasFile = true;
  }
  removeFile(event: Event) {
    event.stopPropagation();
    this.fileInput()!.nativeElement.value = '';
    this.hasFile = false;
    this.hasError = false;
    this.selectedFile = null;
    this.filePreview = null;
    this.fileId = null;
    this.attachment = undefined;
    this._onChange(null);
  }

  private isValidFileType(file: File): boolean {
    return this.acceptedFormats().some(format => file.name.toLowerCase().endsWith(format));
  }

  private setError(message: string) {
    this.hasError = true;
    this.errorMessage = message;
  }
  get control() {
    return this.ngControl.control;
  }
  checkForErrorMessages() {
    if (!this.control || !this.control.errors) {
      return;
    }
    this.setError(getInputFirstErrorMessage(this.control.errors).message)
  }
  get fileInfo(): Attachment | undefined {
    if (this.selectedFile) {
      return {
        contentType: this.selectedFile.type,
        name: this.selectedFile.name,
        fileAttachmentId: null,
        size: this.selectedFile.size,
        url: ''
      }
    } else if (this.attachment)
      return this.attachment;
    else
      return undefined
  }
  get isImage(){
    return this.fileInfo?.contentType.startsWith('image/');
  }
}