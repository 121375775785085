/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule, DatePipe } from '@angular/common';
import { Component, forwardRef, input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SafeAny } from '@core/types';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { DateTimeFormatDirective, PreventInputTypingDirective } from '@shared/directives';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrl: './date-range-picker.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DateTimeFormatDirective,
    DatePipe,
    PreventInputTypingDirective
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true
    }
  ]
})
export class DateRangePickerComponent implements OnInit, ControlValueAccessor {
  labelFrom = input('From');
  labelTo = input('To');
  formGroup = input.required<FormGroup>();
  fromControlName = input.required<string>();
  toControlName = input.required<string>();

  fromDateTime!: FormControl;
  toDateTime!: FormControl;
  minToDate: Date | null = null;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange = (value: SafeAny) => { };
  private onTouched = () => { };

  ngOnInit() {
    this.fromDateTime = this.formGroup().get(this.fromControlName()) as FormControl;
    this.toDateTime = this.formGroup().get(this.toControlName()) as FormControl;

    this.fromDateTime.valueChanges.subscribe(() => {
      this.updateToDateMin();
    });
  }

  updateToDateMin() {
    const fromDate = this.fromDateTime.value;
    if (fromDate) {
      this.minToDate = new Date(fromDate);
      this.minToDate.setSeconds(this.minToDate.getSeconds() + 1);
    } else {
      this.minToDate = null;
    }
  }

  writeValue(value: SafeAny): void {
    if (value) {
      this.formGroup().setValue(value, { emitEvent: false });
    } else {
      this.formGroup().reset();
    }
    this.updateToDateMin();
  }

  registerOnChange(fn: SafeAny): void {
    this.onChange = fn;
    this.formGroup().valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: SafeAny): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup().disable();
    } else {
      this.formGroup().enable();
    }
  }
}