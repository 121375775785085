import { FormGroup } from "@angular/forms";

export function resetFormGroup(formGroup: FormGroup): void {
    formGroup.reset();

    Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.get(key);
        control?.setErrors(null);
        control?.markAsUntouched();
        control?.markAsPristine();
    });

    formGroup.updateValueAndValidity();
}