@let chipLabel = label();
@let chipPlaceholder = placeholder();
@let chipSeparatorKeysCodes = separatorKeysCodes();
@let chipIsRequired = required();
@let chipAddOnBlur = addOnBlur();
<mat-form-field [class.has-chips]="items.length > 0">
    <mat-label>{{chipLabel}}</mat-label>
    <div class="app-chip-grid-container">
        <mat-chip-grid 
        [required]="chipIsRequired"
            #chipGrid [attr.aria-label]="'Enter' + chipLabel" 
            [formControl]="formControl">
            @for (item of items; track item) {
            <mat-chip-row (removed)="remove(item)">
                {{item}}
                <button matChipRemove [attr.aria-label]="'remove ' + item">
                    <span class="fa-solid fa-xmark"></span>
                </button>
            </mat-chip-row>
            }
            <input 
                [placeholder]="chipPlaceholder" 
                [matChipInputFor]="chipGrid"
                [matChipInputSeparatorKeyCodes]="chipSeparatorKeysCodes" 
                [matChipInputAddOnBlur]="chipAddOnBlur"
                (matChipInputTokenEnd)="add($event)">
        </mat-chip-grid>
    </div>
    <mat-error>{{error.message}}</mat-error>
</mat-form-field>