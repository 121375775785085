import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@core/models';

@Component({
  selector: 'app-confirm-box',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatRippleModule
  ],
  templateUrl: './confirm-box.component.html',
  styleUrl: './confirm-box.component.scss'
})
export class ConfirmBoxComponent {
  public dialogRef = inject(MatDialogRef);
  public data: DialogData = inject(MAT_DIALOG_DATA);
  cancel() {
    this.dialogRef.close(false);
  }
  ok(){
    this.dialogRef.close(true);
  }
}
