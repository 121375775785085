import { Pipe, PipeTransform } from '@angular/core';
import { KYCStatusEnum } from '@core/enums';
import { VerificationStatus } from '@core/models';
import { getKycStatus } from '@core/utils';

@Pipe({
  name: 'kycStatus',
  standalone: true,
})
export class KycStatusPipe implements PipeTransform {
  transform(status: KYCStatusEnum): VerificationStatus {
    return getKycStatus(status);
  }
}