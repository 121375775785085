import { Pipe, PipeTransform } from '@angular/core';
import { BankAccountVerificationStatusEnum } from '@core/enums';
import { VerificationStatus } from '@core/models';
import { getBankAccountVerificationRequestStatus } from '@core/utils';

@Pipe({
  name: 'bankAccountVerificationStatus',
  standalone: true
})
export class BankAccountVerificationStatusPipe implements PipeTransform {

  transform(status: BankAccountVerificationStatusEnum): VerificationStatus {
    return getBankAccountVerificationRequestStatus(status);
  }

}
