import { Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CrudActionEnum } from '@core/enums';
import { AppSnackBarService, HttpService } from '@core/services';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [],
  template: '',
})
export abstract class CrudComponent<T, FT = never> {
  action: CrudActionEnum;
  entity: T | undefined;
  form!: FormGroup;
  subscription!: Subscription;
  CrudActionEnum = CrudActionEnum;
  /**
   * Consumers must specify the form type generic value to initialize 
   * readonly fields for the edit mode of the consumer entity
   */
  readOnlyFormControlNames: (keyof FT)[] | undefined;
  /**
   * Inject dependencies required for the component.
   */
  readonly appSnackBarService = inject(AppSnackBarService);
  readonly httpService = inject(HttpService);
  readonly location = inject(Location);
  private readonly activatedRoute = inject(ActivatedRoute);

  /**
   * Initialize the component by determining the action (create or update)
   * based on the presence of the entity. Ensure that the route's data
   * contains the entity or is named correctly to resolve the action mode.
   */
  constructor() {
    this.entity = this.activatedRoute.snapshot.data['entity'] as T;
    this.action = this.entity ? CrudActionEnum.Update : CrudActionEnum.Create;
  }

  /**
   * Abstract methods for handling CRUD operations.
   * Implementations should define the specific behavior for creating and updating
   * based on the action type.
   */
  abstract update(): void;
  abstract create(): void;

  /**
   * Handle form submission by invoking the appropriate method
   * based on the current action (create or update).
   */
  submit() {
    if (this.action === CrudActionEnum.Create) {
      this.create();
    } else if (this.action === CrudActionEnum.Update) {
      this.update();
    }
  }

  /**
   * Get the form data cast to the generic type T.
   * This method returns the current value of the form as type T.
   */
  get payload(): T {
    return this.form.value as T;
  }
  get fc() {
    return this.form.controls
  }
  get dataTableUrl() {
    return this.action === CrudActionEnum.Create ? ['../'] : ['../..']
  }
}
