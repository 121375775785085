import { ToastType } from "@core/types";

export function getToastIcon(toastType: ToastType): string {
    switch (toastType) {
        case "success":
            return "fa-solid fa-check";
        case "info":
            return "fa-solid fa-info";
        case "warning":
            return "fa-solid fa-exclamation";
        case "error":
            return "fa-solid fa-xmark";
        default:
            return "";
    }
}