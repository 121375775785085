import { CommonModule, DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, contentChild, contentChildren, effect, inject, input, OnDestroy, OnInit, signal, viewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatColumnDef, MatTable, MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { PAGINATION_ROWS_COUNTS } from '@core/constants';
import { ApiResponsePaginator, DataTableConfig, PaginatedResponse, PaginationQueryParams } from '@core/models';
import { HttpService, QueryParamsMapperService } from '@core/services';
import { PageHeaderComponent } from '@shared/layout';
import { map, Subject, Subscription } from 'rxjs';
import { DataTableFilterComponent } from '../data-table-filter/data-table-filter.component';
import { CdkTableDataSourceInput } from '@angular/cdk/table';

@Component({
  selector: 'app-data-table',
  standalone: true,
  imports: [
    CommonModule,
    PageHeaderComponent,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterModule,
    DatePipe,
    MatPaginatorModule,
    DataTableFilterComponent
  ],
  templateUrl: './data-table.component.html',
  styleUrl: './data-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableComponent<T, MapTo> implements OnInit, AfterContentInit, OnDestroy {
  private _httpService = inject(HttpService);
  private _queryParamsMapperService = inject(QueryParamsMapperService)
  private _queryParamsSignal = signal<PaginationQueryParams>({
    paging: {
      size: PAGINATION_ROWS_COUNTS[0],
      index: 0
    },
  });
  loading$ = new Subject<boolean>();
  paginatorData!: ApiResponsePaginator | undefined;
  dataSource: T[] | MapTo[] = [];

  config = input.required<DataTableConfig<T, MapTo>>()

  columnDefs = contentChildren(MatColumnDef)
  filters = contentChild(DataTableFilterComponent)

  paginator = viewChild(MatPaginator);
  table = viewChild(MatTable)
  pageSizeOptions = PAGINATION_ROWS_COUNTS;
  cdr = inject(ChangeDetectorRef);
  subscription = new Subscription();
  constructor() {
    effect(() => this._fetchData());
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngOnInit() {
    this._queryParamsSignal.update(params => ({
      paging: {
        ...params.paging, ...this.config().paginator!
      },
      orders: {
        ...params.orders,
        ...this.config().orders
      }
    }));
  }

  ngAfterContentInit() {
    this.columnDefs().forEach(columnDef => {
      this.table()!.addColumnDef(columnDef);
    });
  }
  onPageChange(event: PageEvent) {
    const { pageSize, pageIndex } = event;
    this._updateQueryParams({ paging: { size: pageSize, index: pageIndex } })
  }
  filter(filters: Record<string, unknown>) {
    this._updateQueryParams({ filter: filters })
  }
  clearFilters() {
    this._queryParamsSignal.update((params: PaginationQueryParams) => ({
      paging: { ...params.paging }
    }));
  }
  private _fetchData() {
    this.loading$.next(true);
    this.subscription.add(this._httpService.get<PaginatedResponse<T>>(
      this.config().endpoint,
      new HttpParams({ fromObject: { ...this._queryParamsMapperService.mapQueryParams<PaginationQueryParams>(this.queryParams) } })
    ).pipe(
      map(data => this.config().map ? this.config().map!(data) : data)
    ).subscribe({
      next: ({ data }) => {
        this.dataSource = data.items;
        this.paginatorData = data.base;
        this.cdr.detectChanges();
      },
      complete: () => {
        this.loading$.next(false)
      },
      error: () => {
        this.loading$.next(false)
      }
    }));
  }
  private _updateQueryParams(newParams: Partial<PaginationQueryParams>) {
    this._queryParamsSignal.update((params: PaginationQueryParams) => ({
      paging: { ...params.paging, ...newParams.paging },
      filter: { ...params.filter, ...newParams.filter },
      orders: { ...params.orders, ...newParams.orders },
    }));
  }
  get queryParams() {
    return this._queryParamsSignal();
  }
  get tableDataSource(): CdkTableDataSourceInput<T> {
    return this.dataSource as CdkTableDataSourceInput<T>;
  }
}