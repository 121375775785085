@let optionTemplate = content();
@let value = valueKey();
<mat-form-field class="w-full">
    <mat-label>{{label()}}</mat-label>
    <mat-select class="app-mat-mdc-select" [(value)]="selectedValue" (openedChange)="onOpenChange($event)"
        (selectionChange)="onChange($event.value[value])" (closed)="onTouched()">
        <mat-select-trigger>
            <ng-container *ngIf="selectedValue">
                <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: selectedValue }"></ng-container>
            </ng-container>
        </mat-select-trigger>
        <div class="app-mat-select-options" appScrollEnd (scrollEnd)="onScroll()">
            <mat-option></mat-option>
            <mat-option *ngIf="isLoading && items.length === 0" disabled>Loading...</mat-option>
            <mat-option *ngIf="errorMessage" disabled>{{errorMessage}}</mat-option>
            <mat-option *ngFor="let item of items" [value]="item">
                <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: item }"></ng-container>
            </mat-option>
            <mat-option *ngIf="isLoading && items.length > 0" disabled>Loading more...</mat-option>
            <mat-option *ngIf="!isLoading && !hasMoreData && items.length > 0" disabled>No more items</mat-option>
        </div>
    </mat-select>
</mat-form-field>