@if(config.titleVisible){
  <app-page-header [title]="title"/>
}
<mat-card appearance="outlined" class="app-mat-mdc-card-outlined">
  <mat-card-content class="px-xxl pb-xxl">
    <nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false">
      @for (tab of tabs; track $index) {
        <a
          mat-tab-link
          [routerLink]="tab.path"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive"
        >
          {{ tab.label }}
        </a>
      }
    </nav>
    <mat-tab-nav-panel #tabPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </mat-card-content>
</mat-card>