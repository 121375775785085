import { generateUUID } from "@core/utils";
import { NavItem } from "./nav-item";

export const NavItems: NavItem[] = [
  {
    id: generateUUID(),
    enable: true,
    label: 'Dashboard',
    icon: 'fa-regular fa-house',
    link: '/cpl/dashboard'
  },
  {
    id: generateUUID(),
    enable: true,
    label: 'Exchange Rate',
    icon: 'fa-regular fa-sliders',
    link: '/cpl/exchange-rates',
    children: [
      {
        id: generateUUID(),
        enable: true,
        label: 'Update Rate',
        icon: 'fa-solid fa-circle',
        link: '/cpl/exchange-rates/update',
      },
      {
        id: generateUUID(),
        enable: true,
        label: 'Reports',
        icon: 'fa-solid fa-circle',
        link: '/cpl/exchange-rates/reports',
      }
    ]
  },
  {
    id: generateUUID(),
    enable: true,
    label: 'Verifications',
    icon: 'fa-regular fa-circle-check',
    link: '/cpl/verifications',
    children: [
      {
        id: generateUUID(),
        enable: true,
        label: 'KYC Requests',
        icon: 'fa-solid fa-circle',
        link: '/cpl/verifications/KYCs',
      },
      {
        id: generateUUID(),
        enable: true,
        label: 'Bank Verify',
        icon: 'fa-solid fa-circle',
        link: '/cpl/verifications/banks',
      }
    ]
  },
  {
    id: generateUUID(),
    enable: true,
    label: 'User Management',
    icon: 'fa-regular fa-user-gear',
    link: '/cpl/user-management',
    children: [
      {
        id: generateUUID(),
        enable: true,
        label: 'Customers',
        icon: 'fa-solid fa-circle',
        link: '/cpl/user-management/customers',
      },
      {
        id: generateUUID(),
        enable: true,
        label: 'Experts',
        icon: 'fa-solid fa-circle',
        link: '/cpl/user-management/experts',
      }
    ]
  },
  {
    id: generateUUID(),
    enable: true,
    label: 'Wallet Management',
    icon: 'fa-regular fa-credit-card',
    link: '/cpl/wallet-management'
  },
  {
    id: generateUUID(),
    enable: true,
    label: 'Configurations',
    icon: 'fa-regular fa-sliders',
    link: '/cpl/configurations',
    children: [
      {
        id: generateUUID(),
        enable: true,
        label: 'Banks Management',
        icon: 'fa-solid fa-circle',
        link: '/cpl/configurations/banks',
      },
      {
        id: generateUUID(),
        enable: true,
        label: 'Currency',
        icon: 'fa-solid fa-circle',
        link: '/cpl/configurations/currencies',
      },
      {
        id: generateUUID(),
        enable: true,
        label: 'KYC Providers',
        icon: 'fa-solid fa-circle',
        link: '/cpl/configurations/kyc-providers',
      }
    ]
  }
]
