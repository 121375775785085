import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { SidePanelService } from '@core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidepanel',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatSidenavModule],
  providers: [SidePanelService],
  templateUrl: './sidepanel.component.html',
  styleUrl: './sidepanel.component.scss',
})
export class SidePanelComponent implements AfterViewInit, OnDestroy {
  @ViewChild('panel', { read: ViewContainerRef }) viewContainerRef!: ViewContainerRef;
  @ViewChild('sidepanel') sidePanel!: MatDrawer;
  subscription!: Subscription;

  constructor(private sidePanelService: SidePanelService, private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.sidePanelService.setRootViewContainerRef = this.viewContainerRef;
    this.subscription = this.sidePanelService.toggleSidePanel.subscribe(open => {
      if (open) this.sidePanel.open();
      else this.sidePanel.toggle();
      this.cdr.detectChanges();
    });
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  get extraStyles() {
    return this.sidePanelService.extraStyles;
  }
}
