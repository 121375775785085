import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolean',
  standalone: true
})
export class BooleanPipe implements PipeTransform {

  /**
 * Transforms a boolean value into customizable text.
 * 
 * @param value - The boolean value to be transformed.
 * @param trueLabel - The text to display when the value is true (default is 'Yes').
 * @param falseLabel - The text to display when the value is false (default is 'No').
 * @returns A string representing the boolean value based on the provided labels.
 *
 * Example usage:
 * {{ someBooleanValue | booleanToText }}  // Outputs 'Yes' or 'No'
 * {{ someBooleanValue | booleanToText:'Enabled':'Disabled' }}  // Outputs 'Enabled' or 'Disabled'
 */
  transform(value: boolean, trueLabel = 'Yes', falseLabel = 'No'): string {
    return value ? trueLabel : falseLabel;
  }

}
