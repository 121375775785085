import { Injectable, inject } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { AppSnackbarComponent } from '@shared/components';
export type SnackbarType = 'success' | 'info' | 'warning' | 'error';

@Injectable({
  providedIn: 'root'
})
export class AppSnackBarService extends MatSnackBar {
  config = inject(MAT_SNACK_BAR_DEFAULT_OPTIONS);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  override open(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<any> {
    return this.openSnackBar(message, action, 'info', config);
  }

  private openSnackBar(message: string, action: string | undefined, type: SnackbarType, config?: MatSnackBarConfig): MatSnackBarRef<AppSnackbarComponent> {
    const mergedConfig = { ...this.config, ...config };

    mergedConfig.data = {
      ...mergedConfig.data,
      message,
      action,
      type
    };

    return this.openFromComponent(AppSnackbarComponent, mergedConfig);
  }

  success(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<AppSnackbarComponent> {
    return this.openSnackBar(message, action, 'success', config);
  }

  info(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<AppSnackbarComponent> {
    return this.openSnackBar(message, action, 'info', config);
  }

  warning(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<AppSnackbarComponent> {
    return this.openSnackBar(message, action, 'warning', config);
  }

  error(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<AppSnackbarComponent> {
    return this.openSnackBar(message, action, 'error', config);
  }
}