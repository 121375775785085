@let inputLabel = label();
<mat-form-field class="hide-hint">
  <mat-label>{{inputLabel}}</mat-label>
  <input
    matInput
    type="password"
    [formControl]="passwordControl"
    autocomplete="new-password"
    required
    #passwordInput
  />
  <app-password-toggler
    matSuffix
    [inputElement]="passwordInput"
  ></app-password-toggler>
</mat-form-field>

<div class="grid grid-cols-1 xl:grid-cols-2 gap-2 ml-l mt-xxs Caption">
  @for (condition of conditions; track $index) {
    <span
      [ngClass]="{
        'text-green-base': condition.met,
        'text-secondary-70': isFirstChange,
        'text-red-base': !isFirstChange && !condition.met,
      }"
    >
      {{ condition.text }}
    </span>
  }
</div>
