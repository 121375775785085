/* eslint-disable @typescript-eslint/no-empty-function */
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl, ReactiveFormsModule, FormControl, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipGrid, MatChipInput, MatChipInputEvent, MatChipRemove, MatChipRow } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ValidationError } from '@core/interfaces';
import { SafeAny } from '@core/types';
import { getInputFirstErrorMessage, transformToBoolean } from '@core/utils';
import { MatErrorMessagesComponent } from '@shared/components';

@Component({
  selector: 'app-mat-chip-input',
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    MatChipInput,
    MatChipRow,
    MatChipGrid,
    MatChipRemove,
    ReactiveFormsModule,
    MatIconModule,
    NgFor,
    NgIf,
    NgClass,
    MatErrorMessagesComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './mat-chip-input.component.html',
  styleUrl: './mat-chip-input.component.scss',
})
export class MatChipInputComponent implements ControlValueAccessor, OnInit {
  label = input('Items');
  addOnBlur = input(true, {
    transform:transformToBoolean
  });
  required = input(true, {
    transform:transformToBoolean
  });
  /**
   * @description
   * Adds a chip when pressing Enter, Comma, or Space.
   * You can customize the keys that trigger the addition of a chip by specifying their key codes.
   */
  separatorKeysCodes = input([13, 188, 32]);
  placeholder = input('New Item...');

  items: SafeAny[] = [];
  onChange: SafeAny = () => { };
  onTouched: SafeAny = () => { };

  ngControl = inject(NgControl);
  /**
   * @description
   * We need this formControl for handeleing error messages comes form the consumer
   */
  formControl!: FormControl;

  error: ValidationError = { message: '' };
  errorState = false;
  constructor() {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnInit() {
    if (this.ngControl) {
      this.formControl = this.ngControl.control as FormControl;
    } else {
      this.formControl = new FormControl([]);
    }
  }
  writeValue(value: string[]): void {
    this.items = value || [];
    this.checkForErrorMessages();
  }

  registerOnChange(fn: SafeAny): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: SafeAny): void {
    this.onTouched = fn;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.items.push(value);
      this.onChange(this.items);
      this.onTouched();
    }
    this.checkForErrorMessages();
    event.chipInput!.clear();
  }

  remove(item: string): void {
    const index = this.items.indexOf(item);
    if (index >= 0) {
      this.items.splice(index, 1);
      this.onChange(this.items);
      this.onTouched();
    }
    this.checkForErrorMessages();
  }

  checkForErrorMessages() {
    if (!this.formControl || !this.formControl.errors) {
      return;
    }
    this.error = getInputFirstErrorMessage(this.formControl.errors)
  }
}