@let verificationHistories = histories();
<h2 class="Subtitle-1 my-xxxl">Status History</h2>
<section class="verfication-histories">
    @for (history of verificationHistories; track $index) {
    <article class="verfication-histories__row">
        <div class="verfication-histories__item px-xxl grid grid-cols-3">
            <div class="col-span-1">
                <h3 class="Body-1 text-secondary-60 mb-s">Status:</h3>
                <p class="Subtitle-1 text-secondary-10 mt-0">
                    <app-status [data]="history.status | verificationRequestStatus" [showBorder]="true"></app-status>
                </p>
            </div>
            <div class="col-span-1">
                <h3 class="Body-1 text-secondary-60 mb-s">Changed By:</h3>
                <p class="Subtitle-1 text-secondary-10 mt-0">{{ history?.changedBy || 'N/A' }}</p>
            </div>
            <div class="col-span-1">
                <h3 class="Body-1 text-secondary-60 mb-s">Created On:</h3>
                @if (history?.createdOnUtc) {
                <p class="Subtitle-1 text-secondary-10 mt-0 mb-xxxs">
                    <time [attr.datetime]="history.createdOnUtc | date: 'yyyy-MM-dd'">
                        {{ history.createdOnUtc | date: 'MM/dd/yyyy' }}
                    </time>
                </p>
                <p class="Subtitle-2 text-secondary-60 mt-0">
                    <time [attr.datetime]="history.createdOnUtc | date: 'HH:mm:ss'">
                        {{ history.createdOnUtc | date: 'mediumTime' }}
                    </time>
                </p>
                } @else {
                <p class="Subtitle-1 text-secondary-10 mt-0">N/A</p>
                }
            </div>
            @if (history?.comment) {
                <div class="col-span-5">
                    <h3 class="Body-1 text-secondary-60 mb-s mt-0">Summary:</h3>
                    <p class="Subtitle-1 text-secondary-10 mt-0">{{ history.comment }}</p>
                </div>
                }
        </div>
    </article>
    }
</section>