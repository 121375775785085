import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDownloadFile]',
  standalone: true
})
export class DownloadFileDirective {
  @Input() fileUrl: string | null | ArrayBuffer = null;
  @Input() fileName = '';
  @Input() mimeType = 'application/octet-stream';

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.fileUrl) {
      if (typeof this.fileUrl === 'string') {
        this.downloadFromString(this.fileUrl);
      } else if (this.fileUrl instanceof ArrayBuffer) {
        this.downloadFromArrayBuffer(this.fileUrl);
      }
    }
  }

  private downloadFromString(url: string) {
    const anchor = document.createElement('a');
    anchor.href = url;

    if (url.startsWith('blob:') || url.startsWith('data:')) {
      anchor.download = this.fileName || 'download';
    } else {
      anchor.download = this.fileName || this.getFileNameFromUrl(url);
    }

    anchor.click();
  }

  private downloadFromArrayBuffer(buffer: ArrayBuffer) {
    const blob = new Blob([buffer], { type: this.mimeType });
    const url = window.URL.createObjectURL(blob);
    this.downloadFromString(url);
    window.URL.revokeObjectURL(url);
  }

  private getFileNameFromUrl(url: string): string {
    return url.split('/').pop() || 'download';
  }
}
