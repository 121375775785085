<div class="full-width sidepanel-header-wrapper">
    <div class="header-label">
        @if (useBackButton()) {
        <button (click)="backPage()">Back</button>
        }
        <div class="title">{{title()}}</div>
    </div>
    <div class="actions">
        <ng-content select="button"></ng-content>
    </div>
</div>