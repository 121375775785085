import { Pipe, PipeTransform } from '@angular/core';
import { TruncatePostion } from '@core/types';


@Pipe({
  name: 'truncate',
  standalone: true
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, maxLength = 15, position: TruncatePostion = 'middle'): string {
    if (!value || value.length <= maxLength) {
      return value;
    }

    if (position === 'beginning') {
      return `...${value.slice(-maxLength)}`;
    } else if (position === 'middle') {
      const start = value.slice(0, Math.floor((maxLength - 3) / 2));
      const end = value.slice(-Math.floor((maxLength - 3) / 2));
      return `${start}...${end}`;
    } else { // 'end'
      return `${value.slice(0, maxLength - 3)}...`;
    }
  }
}
