import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES, USER_CREADENTIALS_STORAGE_KEY } from '@core/constants';
import { ApiResponse, JWT } from '@core/models';
import { jwtCredentials } from '@core/signals';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpService } from './http.service';
import { Credentials, LoginDto } from '@core/dtos';
import { EncryptionService } from './encryption.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _router = inject(Router);
  private _httpService = inject(HttpService);
  private _encryptionService = inject(EncryptionService);
  login(loginCredentials: LoginDto): Observable<JWT | null> {
    return this._httpService.post<ApiResponse<JWT>>(ROUTES.auth.login, loginCredentials).pipe(
      tap((credentials) => {
        this._saveCredentials({ ...credentials.data, ...loginCredentials})
      }),
      map(response => response.data)
    );
  }

  getRefreshToken(): Observable<JWT | null> {
    const refreshToken = jwtCredentials()?.refreshToken;
    if (!refreshToken) {
      this.logout();
      return of(null);
    }
    return this._httpService.post<JWT>(ROUTES.auth.refresh_token, { refresh_token: refreshToken }).pipe(
      tap((credentials: JWT) => {
        jwtCredentials.set(credentials);
      }),
      catchError(error => {
        console.error('Refresh token error', error);
        this.logout();
        return of({ accessToken: '', refreshToken: '' } as JWT);
      })
    );
  }

  logout(): void {
    this._router.navigate(['/login']);
    this._clearCredentials();
  }
  logoutFromServer() {
    this._httpService.get<ApiResponse<unknown>>(ROUTES.auth.logout).subscribe()
    this.logout();
  }
  private _saveCredentials(credentials:Credentials) {
    jwtCredentials.set({accessToken:credentials.accessToken, refreshToken:credentials.refreshToken}); 
    const encryptedCredentials = this._encryptionService.encrypt(JSON.stringify(credentials));
    localStorage.setItem(USER_CREADENTIALS_STORAGE_KEY, encryptedCredentials);
  }

  restoreCredentials() {
    const encryptedCredentials = localStorage.getItem(USER_CREADENTIALS_STORAGE_KEY);
    if (encryptedCredentials) {
      try {
        const decryptedCredentials = this._encryptionService.decrypt(encryptedCredentials);
        const credentials = JSON.parse(decryptedCredentials) as Credentials
        return credentials;
      } catch (error) {
        console.error('Failed to decrypt credentials:', error);
        this._clearCredentials();
      }
    }
    return null;
  }

  private _clearCredentials() {
    const credentials = this.restoreCredentials();
    if(credentials?.rememberMe){
      credentials.accessToken = '';
      credentials.refreshToken = '';
      this._saveCredentials(credentials)
    }else{
      jwtCredentials.set(null);
      localStorage.removeItem(USER_CREADENTIALS_STORAGE_KEY);
    }
  }
}
