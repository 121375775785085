import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { VALIDATION_ERROR_MESSAGES, REGEXES } from "@core/constants";
import { ValidationError } from "@core/interfaces";

export class CommonValidators extends Validators {

    static customPattern(pattern: string | RegExp, label: string): ValidatorFn {
        return (control: AbstractControl): Record<string, ValidationError> | null => {
            const invalid = Validators.pattern(pattern);
            const errors = invalid(control);
            if (errors) {
                return { pattern: VALIDATION_ERROR_MESSAGES['pattern']({ label }) };
            }
            return null;
        };
    }
    static minMaxValidator(): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const minControl = formGroup.get('min');
            const maxControl = formGroup.get('max');

            if (!minControl || !maxControl) {
                return null;
            }

            const minValue = minControl.value;
            const maxValue = maxControl.value;

            if (minValue !== null && maxValue !== null && maxValue < minValue) {
                // Assign the error to the max control
                maxControl.setErrors({ minMaxInvalid: { message: 'Max value must be greater than or equal to Min value' } });
            } else {
                if (maxControl.hasError('minMaxInvalid')) {
                    const errors = maxControl.errors;
                    if (errors) {
                        delete errors['minMaxInvalid'];
                        if (Object.keys(errors).length === 0) {
                            maxControl.setErrors(null);
                        } else {
                            maxControl.setErrors(errors);
                        }
                    }
                }
            }

            return null;
        };
    }
    // Custom min value validator
    static override min(min: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (value !== null && value < min) {
                return { min: VALIDATION_ERROR_MESSAGES['min']({ min }) };
            }
            return null;
        };
    }

    // Custom max value validator
    static override max(max: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (value !== null && value > max) {
                return { max: VALIDATION_ERROR_MESSAGES['max']({ max }) };
            }
            return null;
        };
    }
    static override minLength(minLength: number): ValidatorFn {
        return (control: AbstractControl): Record<string, ValidationError> | null => {
            const invalid = Validators.minLength(minLength);
            const errors = invalid(control);
            if (errors) {
                return { minlength: VALIDATION_ERROR_MESSAGES['minlength']({ minLength }) };
            } else {
                return null;
            }
        };
    }

    static override maxLength(maxLength: number): ValidatorFn {
        return (control: AbstractControl): Record<string, ValidationError> | null => {
            const invalid = Validators.maxLength(maxLength);
            const errors = invalid(control);
            if (errors) {
                return { maxlength: VALIDATION_ERROR_MESSAGES['maxlength']({ maxLength }) };
            } else {
                return null;
            }
        };
    }

    static override email(control: AbstractControl): ValidationErrors | null {
        return CommonValidators.customPattern(REGEXES.email, 'Email address')(control);;
    }
    static password(control: AbstractControl): ValidationErrors | null {
        return CommonValidators.customPattern(REGEXES.password, 'Password')(control);;
    }
    static matchPassword(formGroup: FormGroup) {
        const password = formGroup.get('password');
        const confirmPassword = formGroup.get('confirmPassword');
        if (password != null && confirmPassword != null)
            if (password.value != confirmPassword.value) {
                formGroup.controls['confirmPassword'].setErrors({
                    mismatchPassword: VALIDATION_ERROR_MESSAGES['mismatchPassword']({})
                });
            } else {
                formGroup.controls['confirmPassword'].setErrors(null);
            }
    }

    static cardPrefixNumber(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!Array.isArray(value)) {
                return { notAnArray: true };
            }

            const isValid = value.every((item: string) => /^\d{6}$/.test(item));
            return isValid ? null : {
                invalidCardPrefixNumber: VALIDATION_ERROR_MESSAGES['invalidCardPrefixNumber']({})
            };
        };
    }
}