import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, Scroll } from '@angular/router';
import { BreadCrumb } from '@core/models';
import { Subscription, distinct, filter, from, switchMap, toArray } from 'rxjs';
@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule, MatIconModule],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
})
export class BreadCrumbComponent implements OnInit, OnDestroy {
  private subscription!: Subscription;
  breadcrumbs: BreadCrumb[] = [];

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd || (event instanceof Scroll && event.routerEvent instanceof NavigationEnd)),
        switchMap(() => from(this.createBreadcrumbs(this.activatedRoute.root)).pipe(distinct((x) => x.label), toArray()))
      )
      .subscribe((crumbs) => {
        this.breadcrumbs = crumbs;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private createBreadcrumbs(route: ActivatedRoute, url = '', breadcrumbs: BreadCrumb[] = []): BreadCrumb[] {
    const children = route.children;
    children.forEach(child => {
      const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      const childBreadCrumb = child.snapshot.data['breadcrumb'] as BreadCrumb;
      if (childBreadCrumb && !childBreadCrumb.skip) {
        breadcrumbs.push({
          label: childBreadCrumb.label ?? url,
          icon: childBreadCrumb.icon,
          extraIconStyleClass: childBreadCrumb.extraIconStyleClass ?? '',
          extraLabelStyleClass: childBreadCrumb.extraLabelStyleClass ?? '',
          iconOnly: childBreadCrumb.iconOnly ?? false,
          url: url,
        });
      }
      return this.createBreadcrumbs(child, url, breadcrumbs);
    });
    return breadcrumbs;
  }
}
