<div class="confirm-box">
  <div
    class="confirm-box__title Subtitle-1 flex align-items-center justify-between bg-secondary-95 text-secondary-50"
  >
    <span>Confirmation</span>
    <span class="fa-solid fa-xmark" (click)="cancel()"></span>
  </div>
  <div class="confirm-box__body text-center">
    <div class="confirm-box__message">{{data.message}}</div>
    <div class="confirm_box__footer flex justify-center gap-3">
      <button mat-stroked-button color="primary" (click)="cancel()">Cancel</button>
      <button mat-flat-button color="primary" (click)="ok()">Save</button>
    </div>
  </div>
</div>
