import { NgIf } from '@angular/common';
import { Component, inject, input, OnInit } from '@angular/core';
import { FormGroup, FormGroupName, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ValidationError } from '@core/interfaces';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MatErrorMessagesComponent } from '../mat-error-messages/mat-error-messages.component';
import { CommonValidators } from '@core/validators';
const { required } = CommonValidators;
@Component({
  selector: 'app-mat-range-input',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    NgIf,
    NgxMaskDirective,
    MatCheckbox,
    MatErrorMessagesComponent
  ],
  providers: [provideNgxMask()],
  templateUrl: './mat-range-input.component.html',
  styleUrl: './mat-range-input.component.scss'
})
export class MatRangeInputComponent implements OnInit {

  checkboxLabel = input('Enable Range');
  textPrefix = input('USD');
  precision = input(2);
  minLabel = input('Min');
  maxLabel = input('Max');

  minFormControlName = input<string>('min');
  maxFormControlName = input<string>('max');
  checkboxFormControlName = input<string>('enable');

  private formGroupName = inject(FormGroupName, { self: true });

  error: { min: ValidationError; max: ValidationError } = {
    min: { message: '' },
    max: { message: '' }
  };
  ngOnInit(): void {
    this.onEnabledChange();
  }
  onEnabledChange(): void {
    this.form.updateValueAndValidity();
    this.handleEnableChange();
  }

  private handleEnableChange(): void {
    const controls = [this.maxControl, this.minControl];
    if (this.isEnabled)
      controls.forEach(control => {
        control.enable({ emitEvent: false });
        control.setValidators([required]);
        control.updateValueAndValidity();
      })
    else controls.forEach(control => {
      control.disable({ emitEvent: false });
      control.removeValidators(required);
      control.updateValueAndValidity();
    });
  }

  get form(): FormGroup {
    return this.formGroupName.control;
  }

  get isEnabled(): boolean {
    return this.form.get(this.checkboxFormControlName())?.value;
  }

  get minControl() {
    return this.form.get(this.minFormControlName())!;
  }

  get maxControl() {
    return this.form.get(this.maxFormControlName())!;
  }
}