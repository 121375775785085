import { BankAccountVerificationStatusEnum } from "@core/enums";
import { VerificationStatus } from "@core/models";

export function getBankAccountVerificationRequestStatus(status: BankAccountVerificationStatusEnum): VerificationStatus {
    const statusMap: Record<BankAccountVerificationStatusEnum, VerificationStatus> = {
        [BankAccountVerificationStatusEnum.Accepted]: { text: 'Approved', color: 'green', icon: 'fa-solid fa-check' }, 
        [BankAccountVerificationStatusEnum.Pending]: { text: 'Pending', color: 'blue' },
        [BankAccountVerificationStatusEnum.Rejected]: { text: 'Rejected', color: 'red-light', icon: 'fa-solid fa-xmark' },
    };

    return statusMap[status];
}
