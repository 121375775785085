@let time = dateTime() | date: 'HH:mm';
@let date = dateTime() | date: 'MM/dd/yy';
<span class="flex flex-col gap-1">
    @if(date){
        <time [attr.datetime]="date">
            {{ date }}
        </time>
    }
    @if(time){
        <time [attr.datetime]="time" class="text-secondary-70">
            {{ time }}
        </time>
    }
    @if(!date && !time){
        -
    }
</span>