import { ChangeDetectionStrategy, Component, input, output, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sidepanel-header',
  standalone: true,
  imports: [CommonModule],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sidepanel-header.component.html',
  styleUrl: './sidepanel-header.component.scss'
})
export class SidePanelHeaderComponent {
  title = input<string>();
  useBackButton = input<boolean>();
  back = output<boolean>();

  backPage(): void {
    this.back.emit(true)
  }
}
