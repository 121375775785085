import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { SafeAny } from '@core/types';
import { DataTableFilterService } from '@shared/services';
@Component({
  selector: 'app-data-table-filter',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
  ],
  templateUrl: './data-table-filter.component.html',
  styleUrl: './data-table-filter.component.scss'
})
export class DataTableFilterComponent {
  title = input('Filter');
  dataTableFilterService = inject(DataTableFilterService);
  onFilter = output<Record<string, unknown>>();
  onClearFilters = output();
  form!:FormGroup;
  constructor() {
    effect(()=>{
        this.form = this.dataTableFilterService.form();
    })
  }
  onSubmit() {
    if (this.form && this.form.valid) {
      const filteredValue = Object.keys(this.form.value)
        .filter(key => this.form.value[key] !== null && this.form.value[key] !== undefined && this.form.value[key] !== '')
        .reduce((obj: SafeAny, key) => {
          obj[key] = this.form.value[key];
          return obj;
        }, {});
      if (JSON.stringify(filteredValue) != '{}')
        this.onFilter.emit(filteredValue);
    } else {
      //TODO:show some message
    }
  }
  clearFilters() {
    if(!this.form.pristine){
      this.form.reset();
      this.onClearFilters.emit();
    }
  }
}
