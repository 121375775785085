import { NgIf } from '@angular/common';
import { Component, ElementRef, inject, input, viewChild, effect, ChangeDetectionStrategy, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FileSourceType } from '@core/models';
import { HttpService } from '@core/services';
import { DisableContextMenuDirective } from '@shared/directives';

@Component({
  selector: 'app-secure-image',
  standalone: true,
  imports: [
    DisableContextMenuDirective,
    NgIf,
    MatButtonModule
  ],
  templateUrl: './secure-image.component.html',
  styleUrls: ['./secure-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecureImageComponent {
  addWaterMark = input<boolean>(false);
  canvasRef = viewChild<ElementRef<HTMLCanvasElement>>('container');
  httpService = inject(HttpService);
  src = input.required<string>();
  sourceType = input<FileSourceType>('base64');
  waterMarkColor = input<string>('rgba(0, 0, 0, 0.2)');
  waterMarkFontFamily = input<string>('Roboto');
  waterMarkFontSize = input<string>('16px');
  waterMarkGridSize = input<number>(4);
  waterMarkText = input<string>('Y Project');
  borderRadius = input<number>(12);
  borderColor = input<string>('black');
  loading = signal(false);
  error = signal<string | null>(null);
  private _canvasWidth = 431;
  private _canvasHeight = 287;
  constructor() {
    effect(() => {
      this._loadImage();
    }, { allowSignalWrites: true });
  }
  retry() {
    this._loadImage();
  }
  private _loadImage() {
    if (!this.src()) {
      this.error.set('No image source provided');
      return;
    }
    this.loading.set(true);
    this.error.set(null);
    if (this.sourceType() === 'base64') {
      let base64ImageSrc = this.src();
      if (!base64ImageSrc.startsWith('data:image/')) {
        base64ImageSrc = `data:image/png;base64,${base64ImageSrc}`;
      }
      this._handleImage(base64ImageSrc)
    } else {
      this.httpService.http.get(this.src(), { responseType: 'blob' })
        .subscribe(
          {
            next: (response: Blob) => {
              this.loading.set(false);

              const reader = new FileReader();
              reader.onloadend = () => {
                const img = new Image();
                img.onload = () => {
                  this.drawImageToFit(img);
                };
                img.src = reader.result as string;
              };
              reader.readAsDataURL(response);
            },
            error: error => {
              this.error.set('Failed to load image');
              this.loading.set(false);
              console.error('Error loading image:', error);
            }
          }
        );
    }

  }
  private _handleImage(imageSrc: string) {
    const img = new Image();
    img.onload = () => {
      this.drawImageToFit(img);
    };
    img.src = imageSrc;
    this.loading.set(false);
  }
  private drawImageToFit(img: HTMLImageElement) {
    const canvas = this.canvasRef()!.nativeElement;
    canvas.width = this._canvasWidth;
    canvas.height = this._canvasHeight;
    const ctx = canvas.getContext('2d')!;

    const scale = Math.min(
      this._canvasWidth / img.width,
      this._canvasHeight / img.height
    );
    const scaledWidth = img.width * scale;
    const scaledHeight = img.height * scale;

    const x = (this._canvasWidth / 2) - (img.width / 2) * scale;
    const y = (this._canvasHeight / 2) - (img.height / 2) * scale;
    // Clear the canvas
    ctx.clearRect(0, 0, this._canvasWidth, this._canvasHeight);

    // Create a rounded rectangle path
    this.roundRect(ctx, x, y, scaledWidth, scaledHeight, this.borderRadius());

    // Clip to the current path
    ctx.clip();
    ctx.drawImage(img, x, y, scaledWidth, scaledHeight);
    if (this.addWaterMark())
      this.addWatermarks(ctx);
  }
  private roundRect(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius: number) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
  }
  private addWatermarks(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = this.waterMarkColor();
    ctx.font = `${this.waterMarkFontSize()} ${this.waterMarkFontFamily()}`;

    // Calculate the size of the watermark text
    const textMetrics = ctx.measureText(this.waterMarkText());
    const textWidth = textMetrics.width;

    // Define a grid for watermark placement
    const gridSize = this.waterMarkGridSize();
    const cellWidth = this._canvasWidth / gridSize;
    const cellHeight = this._canvasHeight / gridSize;

    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        // Calculate position for each watermark
        const x = (i + 0.5) * cellWidth - textWidth / 2;
        const y = (j + 0.5) * cellHeight;

        // Rotate the context
        ctx.save();
        ctx.translate(x + textWidth / 2, y);
        ctx.rotate(-Math.PI / 6); // Rotate by -30 degrees
        ctx.fillText(this.waterMarkText(), -textWidth / 2, 0);
        ctx.restore();
      }
    }
  }
}