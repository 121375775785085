import { ValidationErrors } from "@angular/forms";
import { VALIDATION_ERROR_MESSAGES } from "@core/constants";
import { ValidationError } from "@core/interfaces";

export function getInputFirstErrorMessage(errors: ValidationErrors | null): ValidationError {
    let error: ValidationError = { message: '' }
    if (errors) {
        const firstError = Object.keys(errors!).map(key => ({ error: errors![key], key }))[0];
        if (typeof firstError.error === 'boolean')
            error = VALIDATION_ERROR_MESSAGES[firstError.key]({});
        else error = firstError.error;
    }
    return error
}