import { Directive, HostListener, output } from '@angular/core';

@Directive({
  selector: '[appScrollEnd]',
  standalone: true
})
export class ScrollEndDirective {
  scrollEnd = output<void>();

  @HostListener('scroll', ['$event.target'])
  onScroll(target: HTMLElement) {
    const isAtBottom = Math.abs(target.scrollHeight - target.clientHeight - target.scrollTop) < 1;
    if (isAtBottom) {
      this.scrollEnd.emit();
    }
  }
}