import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  private _key = "Vz@AiLK%Fytx+*C"
  encrypt(password: string): string {
    return CryptoJS.AES.encrypt(password, this._key).toString();
  }

  decrypt(passwordToDecrypt: string) {
    return CryptoJS.AES.decrypt(passwordToDecrypt, this._key).toString(CryptoJS.enc.Utf8);
  }
}
