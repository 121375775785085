import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'insertSeparator',
  standalone: true
})
export class InsertSeparatorPipe implements PipeTransform {

  transform(value: string, interval = 4, separator = ' '): string {
    if (!value) return '';

    const regex = new RegExp(`.{1,${interval}}`, 'g');
    return value.match(regex)?.join(separator) || value;
  }

}
