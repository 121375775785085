import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input[appPreventInputTyping]',
  standalone: true
})
export class PreventInputTypingDirective {

  @HostListener('keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    event.preventDefault();
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: ClipboardEvent): void {
    event.preventDefault();
  }
}
