/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegexKey } from "@core/enums";
import { ValidationError, ValidationErrorParams } from "@core/interfaces";

export const REGEXES: Record<RegexKey, RegExp> = {
    [RegexKey.Email]: /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    [RegexKey.Password]: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,

};
export const VALIDATION_ERROR_MESSAGES: Record<string, (params: ValidationErrorParams) => ValidationError> = {
    required: (_) => ({ message: 'This field is required' }),
    minLength: (params) => ({ message: `The number of characters entered cannot be less than ${params.minLength} characters` }),
    maxLength: (params) => ({ message: `The number of characters entered cannot be more than ${params.maxLength} characters` }),
    min: (params) => ({ message: `Value must be greater than or equal to ${params.min}.` }),
    max: (params) => ({ message: `Value must be less than or equal to ${params.max}.` }),
    pattern: (params) => ({ message: `Invalid ${params.label} format.` }),
    mismatchPassword: (_) => ({ message: 'Passwords do not match' }),
    invalidCardPrefixNumber: (_) => ({ message: 'Invalid Card Prefix Number entry. The value must be a 6-digit number.' }),
};