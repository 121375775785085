import { ComponentType } from '@angular/cdk/portal';
import { NgStyle } from '@angular/common';
import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { ComponentInstanceRef } from '@core/models';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SidePanelService {
    toggleSidePanel = new Subject<boolean>();
    public extraStyles: NgStyle["ngStyle"] = {};
    private rootViewContainerRef!: ViewContainerRef;
    private componentRef?: ComponentRef<unknown>;

    constructor(private viewContainerRef: ViewContainerRef) { }

    set setRootViewContainerRef(v: ViewContainerRef) {
        this.rootViewContainerRef = v;
    }

    open<T>(component: ComponentType<unknown>, inputs: Record<string, unknown> = {}, styles: NgStyle['ngStyle'] = {}): ComponentInstanceRef<T> {
        this.extraStyles = styles;
        this.componentRef = this.viewContainerRef.createComponent(component);
        this.componentRef.setInput("spServiceRef", this);
        Object.keys(inputs).forEach(k => {
            this.componentRef?.setInput(k, inputs[k]);
        });
        this.rootViewContainerRef.clear();
        this.rootViewContainerRef.insert(this.componentRef.hostView);
        this.toggleSidePanel.next(true);
        return this.componentRef.instance as ComponentInstanceRef<T>;
    }

    close(): void {
        this.rootViewContainerRef.clear();
        this.toggleSidePanel.next(false);
    }

    update(inputs: Record<string, unknown> = {}, styles?: NgStyle['ngStyle']): void {
        if (this.rootViewContainerRef.length == 0) throw new Error("Root view is empty. Please call open() before updating the SidePanel");
        Object.keys(inputs).forEach(k => {
            this.componentRef?.setInput(k, inputs[k]);
        });
        if (styles) this.extraStyles = styles;
    }
}
