export const ROUTES = {
    auth: {
        login: 'accounts/login',
        logout: 'accounts/logout',
        refresh_token: 'accounts/refresh',
    },
    userManagement: {
        experts: {
            list: '/experts',
            create: '/experts',
            update: '/experts/{id}',
            changePassword: '/experts/{id}/password',
            changePermissions: '/experts/{id}/permissions',
            retrive: '/experts/{id}'
        },
    },
    users: {
        list: '/users',
        create: '/users',
        update: '/users/{id}',
        change_password: '/users/{id}/password',
        retrive: '/users/{id}'
    },
    verifications: {
        kyc: {
            list: '/requests/verifications',
            requestSummary: '/requests/verifications/{id}/summary',
            personalInformationRequest: '/requests/verifications/{id}/personal-information',
            phoneNumberRequest: '/requests/verifications/{id}/phone-number',
        },
        bank: {
            requestSummary: '/bank/requests/verifications/{id}/summary',
            verificationInformation: '/bank/requests/verifications/{id}/account-information',
            list: '/bank/requests/verifications'
        }
    },
    walletManagement: {
        list: '/wallets'
    },
    configuration: {
        banks: {
            list: '/banks',
            create: '/banks',
            update: '/banks',
            retrive: '/banks/{id}'
        },
        currency: {
            list: '/currencies',
            create: '/currencies',
            update: '/currencies/{id}',
            retrive: '/currencies/{id}'
        },
        KYCProviders: {
            list: '/kyc-providers',
            create: '/kyc-providers',
            updateBaseInformation: '/kyc-providers/{id}/base-information',
            updateSettings: '/kyc-providers/{id}/settings',
            updateAPISecurity: '/kyc-providers/{id}/api-security',
            retriveBaseInformation: '/kyc-providers/{id}/base-information',
            retriveSettings: '/kyc-providers/{id}/settings',
            retriveAPISecurity: '/kyc-providers/{id}/api-security'
        }
    },
    file: {
        upload: '/files/upload',
        download: '/files/upload',
        delete: '/files/upload'
    },
    exchangeRate: {
        list: '/exchange-rates',
        update: '/exchange-rates',
        report: '/exchange-rates/report'
    }
}