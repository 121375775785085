import {
  Directive,
  ViewContainerRef,
  ComponentRef,
  ElementRef,
  Renderer2,
  inject,
  input,
  effect
} from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appPromiseButton]',
  standalone: true,
})
export class PromiseButtonDirective {
  private _buttonWidth: string | null = null;
  private _elementRef = inject(ElementRef);
  private _renderer = inject(Renderer2);
  private _originalLabelContent: HTMLElement | null = null;
  private _spinner!: ComponentRef<MatProgressSpinner> | null;
  private _viewContainerRef = inject(ViewContainerRef);
  subscription = input<Subscription | null>(null, { alias: 'appPromiseButton' });
  constructor() {
    effect(() => {
      this._handleSubscriptionChange();
    });
  }

  private _handleSubscriptionChange(): void {
    if (this.subscription() && !this.subscription()?.closed) {
      this._createSpinner();
      this.subscription()?.add(() => this._destroySpinner());
    } else {
      this._destroySpinner();
    }
  }

  private _createSpinner(): void {
    if (!this._spinner) {
      this._storeOriginalLabelContent();
      this._setupButton();
      this._addSpinner();
    }
  }

  private _destroySpinner(): void {
    if (this._spinner) {
      this._restoreOriginalLabelContent();
      this._resetButton();
      this._removeSpinner();
    }
  }

  private _storeOriginalLabelContent(): void {
    this._buttonWidth = `${this._elementRef.nativeElement.offsetWidth}px`;
    this._originalLabelContent = this._elementRef.nativeElement.querySelector('.mdc-button__label') as HTMLElement;
    this._renderer.removeChild(this._elementRef.nativeElement, this._originalLabelContent);
  }

  private _setupButton(): void {
    this._renderer.setStyle(this._elementRef.nativeElement, 'width', this._buttonWidth);
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', true);
  }

  private _addSpinner(): void {
    this._spinner = this._viewContainerRef.createComponent(MatProgressSpinner);
    this._spinner.instance.diameter = 20;
    this._spinner.instance.mode = 'indeterminate';
    this._renderer.appendChild(this._elementRef.nativeElement, this._spinner.instance._elementRef.nativeElement);
  }

  private _restoreOriginalLabelContent(): void {
    if (this._originalLabelContent) {
      this._renderer.appendChild(this._elementRef.nativeElement, this._originalLabelContent);
    }
  }

  private _resetButton(): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', false);
    this._renderer.setStyle(this._elementRef.nativeElement, 'width', 'auto');
  }

  private _removeSpinner(): void {
    if (this._spinner) {
      this._spinner.destroy();
      this._spinner = null;
    }
  }
}
