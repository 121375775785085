<div class="breadcrumb-wrapper text-secondary-70 Caption">
    @for (item of breadcrumbs; track item.url; let last=$last) {
    @if (item.iconOnly) {
    <a [routerLink]="item.url" class="breadcrumb-link text-secondary-70">
        <span [ngClass]="item.icon"></span>
    </a>
    } @else {
    <a [routerLink]="item.url" class="breadcrumb-link text-secondary-70">
        @if (item.icon) {
        <span [ngClass]="item.icon"></span>
        }
        <span>{{ item.label ?? "" }}</span>
    </a>
    }
    @if (!last) {
    <span>/</span>
    }
    }
</div>