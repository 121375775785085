import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { VerificationHistory } from '@core/models';
import { StatusComponent } from '../status/status.component';
import { KycStatusPipe, VerificationRequestStatusPipe } from '@shared/pipes';

@Component({
  selector: 'app-verfication-history',
  standalone: true,
  imports: [
    CommonModule,
    StatusComponent,
    KycStatusPipe,
    VerificationRequestStatusPipe
  ],
  templateUrl: './verfication-history.component.html',
  styleUrl: './verfication-history.component.scss'
})
export class VerficationHistoryComponent {
  histories = input.required<VerificationHistory[]>();
}
