import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  ViewEncapsulation
} from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { Router, RouterModule } from '@angular/router';
import { NavItem } from '@shared/layout';
@Component({
  selector: 'app-nav-item',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule, MatListModule, MatExpansionModule],
  templateUrl: './nav-item.component.html',
  styleUrl: './nav-item.component.scss'
})
export class NavItemComponent {
  navItem = input.required<NavItem>();
  router = inject(Router);

  shouldExpand(navItem: NavItem): boolean {
    if (this.router.url.startsWith(navItem.link)) {
      return true;
    }
    if (navItem.children) {
      return navItem.children.some((child: NavItem) => this.router.url.startsWith(child.link));
    }
    return false;
  }
}
