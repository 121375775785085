<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar class="sidenav-top-toolbar">
      <span class="sidenav-logo"></span>
      <span class="Subtitle-1">Y Project</span>
    </mat-toolbar>
    <mat-nav-list>
      @for (navItem of navItems; track navItem.id) {
        <app-nav-item
          [navItem]="navItem"
        />
      }
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="page-top-toolbar">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <app-page-top-toolbar />
    </mat-toolbar>
    <main class="p-xxl">
      <ng-content />
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
