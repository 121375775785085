import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-date-time-td',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './date-time-td.component.html',
  styleUrl: './date-time-td.component.scss'
})
export class DateTimeTdComponent {
  dateTime = input.required<string>();
}
