import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'env';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DtoWrapper } from '../dtos/wrapper';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  readonly apiRoot = environment.api.url
  private defaultHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private httpClient: HttpClient) { }

  get<T>(endpoint: string, params?: HttpParams, headers?: HttpHeaders): Observable<T> {
    return this.httpClient.get<T>(this.buildUrl(endpoint), {
      headers: this.mergeHeaders(headers),
      params
    }).pipe(
      catchError(this.handleError)
    );
  }

  post<T>(endpoint: string, body: object, headers?: HttpHeaders): Observable<T> {
    return this.httpClient.post<T>(this.buildUrl(endpoint), this.buildPayload<T>(body), {
      headers: this.mergeHeaders(headers)
    }).pipe(
      catchError(this.handleError)
    );
  }

  put<T>(endpoint: string, body: object, headers?: HttpHeaders): Observable<T> {
    return this.httpClient.put<T>(this.buildUrl(endpoint), this.buildPayload<T>(body), {
      headers: this.mergeHeaders(headers)
    }).pipe(
      catchError(this.handleError)
    );
  }

  delete<T>(endpoint: string, params?: HttpParams, headers?: HttpHeaders): Observable<T> {
    return this.httpClient.delete<T>(this.buildUrl(endpoint), {
      headers: this.mergeHeaders(headers),
      params
    }).pipe(
      catchError(this.handleError)
    );
  }

  patch<T>(endpoint: string, body: object, headers?: HttpHeaders): Observable<T> {
    return this.httpClient.patch<T>(this.buildUrl(endpoint), this.buildPayload<T>(body), {
      headers: this.mergeHeaders(headers)
    }).pipe(
      catchError(this.handleError)
    );
  }
  get http(){
    return this.httpClient;
  }
  private buildPayload<T>(body: object) {
    return {
      data: body as T
    } as DtoWrapper<T>
  } 
  buildUrl(endpoint: string): string {
    const normalizedEndpoint = endpoint.startsWith('/') ? endpoint.substring(1) : endpoint;
    return `${this.apiRoot}/${normalizedEndpoint}`;
  }

  private mergeHeaders(headers?: HttpHeaders): HttpHeaders {
    let combinedHeaders = this.defaultHeaders;

    if (headers) {
      headers.keys().forEach(key => {
        combinedHeaders = combinedHeaders.set(key, headers.get(key) as string);
      });
    }

    return combinedHeaders;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error('HTTP error:', error);
    return throwError(() => new Error('An error occurred while processing the HTTP request.'));
  }
}
