import { CommonModule } from '@angular/common';
import { Component, HostBinding, input } from '@angular/core';
import { VerificationStatus } from '@core/models';


@Component({
  selector: 'app-status',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './status.component.html',
  styleUrl: './status.component.scss',
})
export class StatusComponent {
  data = input.required<VerificationStatus>();
  showIcon = input(false);
  showBorder = input(false);
  typography = input('Caption');
  @HostBinding('class')
  get hostClasses(): string {
    return [
      this.typography(),
      'inline-flex',
      'items-center',
      'px-m',
      'py-xxs',
      'rounded-full',
      this.getTextColorClass(),
      this.showBorder() ? 'border border-solid' : '',
    ].filter(Boolean).join(' ');
  }

  get hasIcon(): boolean {
    try{
      return this.showIcon() && !!this.data().icon;
    }catch{
      return false;
    }
  }

  private getTextColorClass(): string {
    const colorMap: Record<string, string> = {
      'red': 'text-red-base',
      'red-light': 'text-red-light',
      'green': 'text-green-base',
      'green-light': 'text-green-light',
      'blue': 'text-blue-base',
      'blue-light': 'text-blue-light',
      'yellow': 'text-yellow-base',
      'yellow-light': 'text-yellow-light',
      'orange': 'text-orange-base',
      'orange-light': 'text-orange-light',
      'gray': 'text-secondary-60',
      'black':'black'
    };
    try{
      return colorMap[this.data().color] || 'text-secondary-60';
    }catch{
      return 'black'
    }
  }
}