<mat-card appearance="outlined" class="app-mat-mdc-card-outlined mb-xxl p-xxl">
  <mat-card-header class="mb-xl">
    <mat-card-title-group>
      <mat-card-title>{{ title() }}</mat-card-title>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content class="px-0">
    <ng-content></ng-content>
  </mat-card-content>
  <mat-card-actions class="actions">
    <button mat-button color="primary" (click)="clearFilters()">Clear</button>
    <button mat-stroked-button color="primary" (click)="onSubmit()">Search</button>
  </mat-card-actions>
</mat-card>
