@let loadingImage = loading();
@let errorOccured = error();
@let imageSrc = src();
<div appDisableContextMenu
    class="secure-image bg-secondary-90 p-xxl flex justify-center items-center text-secondary-60 text-center">
    @if(!loadingImage && !errorOccured && imageSrc){
    <canvas #container></canvas>
    }
    <div>
        @if(loadingImage){
            <span class="fa fa-spinner fa-spin"></span>
        }
        @if(!loadingImage && (!imageSrc || errorOccured)){
            <i class="fa-regular fa-image "></i>
            @if(errorOccured){
                @if(imageSrc){
                    <p>{{ errorOccured }}</p>
                    <button mat-button (click)="retry()">Retry</button>
                }
            }
        }
    </div>
</div>