/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, contentChild, effect, forwardRef, inject, input, OnInit, signal, TemplateRef, viewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SafeAny } from '@core/types';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOption, MatSelect, MatSelectTrigger } from '@angular/material/select';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { HttpService, QueryParamsMapperService } from '@core/services';
import { PaginatedResponse, PaginationQueryParams } from '@core/models';
import { ScrollEndDirective } from '@shared/directives';
import { PAGINATION_ROWS_COUNTS } from '@core/constants';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-async-selector',
  templateUrl: './async-selector.component.html',
  styleUrl: './async-selector.component.scss',
  standalone: true,
  imports: [
    MatFormFieldModule,
    CommonModule,
    MatSelect,
    MatOption,
    NgIf,
    NgFor,
    ScrollEndDirective,
    MatSelectTrigger
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSelectorComponent),
      multi: true
    }
  ]
})
export class AsyncSelectorComponent implements ControlValueAccessor, OnInit {
  private _queryParamsMapperService = inject(QueryParamsMapperService)

  /**
   * @description
   * The URL from which to fetch the data for the options.
   */
  dataUrl = input.required<string>();

  /**
   * @description
   * Specify any supported object by the data source to filter the target data.
   */
  filter = input<Record<string, unknown>>();
  /**
   * @description
   * The label to display for the select input.
   */
  label = input.required<string>();

  /**
   * @description
   * The key in the data object that will be used as the value for the options.
   */
  valueKey = input('id');

  /**
   * @description
   * An array to store the items fetched from the data source.
   */
  items: SafeAny[] = [];

  /**
   * @description
   * Holds the currently selected value in the dropdown.
   */
  selectedValue: SafeAny = null;

  /**
   * @description
   * Indicates whether the data is currently being loaded.
   */
  isLoading = false;

  /**
   * @description
   * Indicates whether any content has been loaded successfully.
   */
  hasContent = false;

  /**
   * @description
   * Stores any error message that occurs during data loading.
   */
  errorMessage: string | null = null;

  /**
   * @description
   * The current page number for paginated data loading.
   */
  private _queryParamsSignal = signal<PaginationQueryParams>({
    paging: {
      size: PAGINATION_ROWS_COUNTS[0],
      index: 0
    },
    filter: {},
    orders: { id: 1 }
  });
  /**
   * @description
   * Indicates whether there is more data to be loaded (for pagination).
   */
  hasMoreData = true;

  /**
   * @description
   * Callback function to handle changes in the selected value.
   */
  onChange: SafeAny = () => { };

  /**
   * @description
   * Callback function to handle when the select input is touched.
   */
  onTouched: SafeAny = () => { };

  /**
   * @description
   * Reference to the MatSelect component in the template.
   */
  matSelect = viewChild(MatSelect);

  /**
   * @description
   * Injected HTTP service used to fetch data from the specified URL.
   */
  httpService = inject(HttpService);

  /**
   * @description
   * Template reference for the content that will be displayed inside the options.
   */
  content = contentChild.required(TemplateRef);
  opened = signal<boolean>(false);
  scrolled = signal<boolean>(false);
  /**
   *
   */
  constructor() {
    effect(() => {
      if (this.opened()) {
        if (this.queryParams.paging.index == 0 && this.items.length === 0) {
          this.loadData();
        }
        if (this.scrolled()) {
          this.scrolled.set(false);
          if (!this.isLoading && this.hasMoreData) {
            this._nextPage();
            this.loadData();
          }
        }
      }
    }, { allowSignalWrites: true });
  }
  ngOnInit(): void {
    this._queryParamsSignal.update((params: PaginationQueryParams) => ({
      ...params,
      filter: { ...params.filter, ...this.filter() },
    }));
  }
  onOpenChange(opened: boolean) {
    this.opened.set(opened);
  }

  onScroll() {
    this.scrolled.set(true);
  }

  loadData() {
    this.isLoading = true;
    this.fetchData().pipe(
      tap(data => {
        this.items = [...this.items, ...data];
        this.isLoading = false;
      }),
      catchError(error => {
        console.error('Error fetching more data:', error);
        this.isLoading = false;
        return of([]);
      })
    ).subscribe();
  }

  fetchData(): Observable<SafeAny[]> {
    const url = `${this.dataUrl()}`;
    const params = new HttpParams({ fromObject: { ...this._queryParamsMapperService.mapQueryParams<PaginationQueryParams>(this.queryParams) } })
    return this.httpService.get<PaginatedResponse<SafeAny>>(url, params).pipe(
      tap(data => {
        this.hasMoreData = data.data.base.total > this.items.length;
      }),
      map(result => result.data.items)
    );
  }

  writeValue(value: SafeAny): void {
    this.selectedValue = value;
  }

  registerOnChange(fn: SafeAny): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: SafeAny): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDisabledState?(_isDisabled: boolean): void {
    // Implement if needed
  }
  private _nextPage() {
    this._queryParamsSignal.update((params: PaginationQueryParams) => ({
      paging: { ...params.paging, index: params.paging.index + 1 },
      filter: { ...params.filter },
      orders: { id: 1 }
    }));
  }
  get queryParams() {
    return this._queryParamsSignal();
  }
}